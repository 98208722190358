//
// Toasts
//
.toast {
	border-color: #d2d9e1 !important;
	font-size: 1rem;
	
	@include dark-mode {
		background-color: $dark-toast-background-color;
	}
}

.toast-header {
	border-bottom-color: #d2d9e1 !important;
	@include dark-mode {
		background-color: $dark-toast-header-background-color;
	}
}
