/**
 *	Styles
 */

@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';
@import 'node_modules/bootstrap/scss/utilities/api';
@import 'custom/index';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap');

// 로그인-회원가입
// .main-bg {
// 	background: url(../assets/img/auth_bg2.png) 100% 0 no-repeat;
// }

.main-bg::before {
	content: '';
	opacity: 0.3;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
}
// 사이드메뉴
.aside-logo {
	// background: url(../assets/img/aside-logo.svg) 50% 50% no-repeat;
	background-size: contain;
	padding: 0.375rem;
	// margin-left: 1rem;

	// a {
	// 	display: block;
	// 	width: 80px;
	// 	height: 45px;
	// }
}

.user-state {
	background: $info;
}

.admin-state {
	background: $success;
}

.color-blue {
	&-light {
		color: #7e9bff;
	}

	&-bg {
		color: #e0e9ff;
	}
}

.bg {
	&-blue {
		background: #e0e9ff;
	}
}
// 직원관리
.tbs {
	&-pinat01 {
		th {
			background-color: #f8f8f8;
		}
	}
}

//공통
.font-num {
	font-family: Montserrat, sans-serif !important;

	&-100 {
		font-family: Montserrat, sans-serif !important;
		font-weight: 100;
	}

	&-500 {
		font-family: Montserrat, sans-serif !important;
		font-weight: 700;
		font-size: 3.5rem;
	}
}

// 로그인 이미지
.login-bg {
	// background: url(../assets/img/login_bg.png) no-repeat;
	background-position: center;
	background-size: cover;
}

.btn-disable-opacity:disabled {
	opacity: 1;
}

.ms-icon {
	// background: url(../assets/img/Microsoft.png) no-repeat;
	background-position: center;
	background-size: cover;
	font-size: 19px;
	width: 19.5px;
	height: 19.5px;
	display: inline-block;
}

.bg-f6 {
	background-color: #f6f6f6 !important;
}
