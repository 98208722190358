//
//	Modal
//

.modal-content {
	padding: 12px;
	@include dark-mode {
		background-color: $dark-modal-content-bg;
	}
}

.modal {
	&-backdrop {
		@include backdrop-filter-blur($modal-backdrop--blur);

		background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);

		&.show {
			opacity: 1;
		}
	}
}
