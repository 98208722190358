//
//  Page Wrapper
//
@use 'sass:map';
@use 'sass:math';

.page-wrapper {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;

	// &.full {
	// 	padding-left: 0;
	// 	padding-right: 0;
	// 	// margin-left: map.get($header, padding-x) * -1;
	// 	// margin-right: map.get($header, padding-x) * -1;
	// }
}

.page-width70 {
	width: 70%;
}
