//
//  Page
//
@use 'sass:map';
@use 'sass:math';

.page {
	@include padding-top(map-get($page, padding-y));

	display: flex;
	flex: 1 0 auto;
	flex-direction: column;

	&.full {
		padding-left: 0;
		padding-right: 0;
		// margin-left: map.get($header, padding-x) * -1;
		// margin-right: map.get($header, padding-x) * -1;
	}

	@include only-safari {
		// height: calc(100% - #{map-get($footer, height)});
	}
}

.page_row {
	@include padding-top(map-get($page, padding-y));

	display: flex;
	flex: 1 0 auto;
	flex-direction: row;

	&.full {
		padding-left: 0;
		padding-right: 0;
		// margin-left: map.get($header, padding-x) * -1;
		// margin-right: map.get($header, padding-x) * -1;
	}

	@include only-safari {
		// height: calc(100% - #{map-get($footer, height)});
	}
}
