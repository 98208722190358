//
//	Pagination
//

.page-link {
	background-color: inherit;
	border: 0;
	@include dark-mode {
		background-color: $dark-pagination-bg;
		border-color: $dark-pagination-border-color;
		color: $dark-pagination-color;

		&:hover {
			background-color: $dark-pagination-hover-bg;
		}
	}
}

.page-item {
	margin: 0 $spacer * 0.25;

	&.active .page-link {
		@include dark-mode {
			@include gradient-bg($pagination-active-bg);
		}
	}

	&.active .page-link {
		background-color: $dark-pagination-bg;
		border-color: $dark-pagination-border-color;
		border-radius: 8px;
	}

	&.disabled .page-link {
		background-color: inherit;
		border: 0;
		@include dark-mode {
			background-color: $dark-pagination-disabled-bg;
			border-color: $dark-pagination-disabled-border-color;
			color: $dark-pagination-disabled-color;
		}
	}
}
